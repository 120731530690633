const light = Object.freeze({
    primary: '#406080',
    secondary: '#A6BEC9',
    navBackground: '#FFFFFF',
    tileBackground: '#FFFFFF',
    appBackground: '#F5F5F5',
    inputBackground: '#FFFFFF',
    logoColor: '#406080',
    error: '#AC3A4A',
    info: '#406080',
    success: '#C1C55C',
    warning: '#AC3A4A',
})

const dark = Object.freeze({
    primary: '#FFFFFF',
    secondary: '#899AA1',
    navBackground: '#2C2C2C',
    tileBackground: '#2C2C2C',
    appBackground: '#1B1B1B',
    inputBackground: '#222222',
    logoColor: '#FFFFFF',
    error: '#E16D7E',
    info: '#A6BEC9',
    success: '#DDE38B',
    warning: '#E16D7E',
})

const props = Object.freeze({
    headerLogoWidth: 38,
    headerLogoSrc: require('@/assets/custom/images/headers/living-header.png')
})

module.exports = Object.freeze({
    light,
    dark,
    props
})

