<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       width="100%" viewBox="0 0 1024 1024" enable-background="new 0 0 1024 1024" xml:space="preserve">
<path :fill="fillColor"  opacity="1.000000" stroke="none"
      d="
M671.737183,175.087234
	C676.862488,175.300415 681.520569,175.616959 686.179993,175.638245
	C689.159851,175.651855 690.200745,176.857880 690.349670,179.810730
	C691.718567,206.952286 691.804565,234.117752 691.016724,261.257782
	C690.322266,285.181000 688.542603,309.071320 686.214172,332.902954
	C685.830627,336.828644 684.818909,340.697601 685.867798,345.590759
	C735.215149,321.398956 784.137512,297.415497 833.304199,273.312286
	C838.877808,283.601471 841.904541,293.967377 844.999451,304.299591
	C845.893982,307.285950 843.815979,307.935211 841.497375,308.499390
	C827.414551,311.926636 813.566284,316.152863 799.942749,321.090149
	C750.156189,339.133179 705.567932,365.845001 666.283203,401.379059
	C645.191162,420.457336 626.072266,441.253784 610.631165,465.255157
	C608.011902,469.326447 605.554993,473.486145 604.070923,478.128418
	C602.008911,484.578613 604.307251,488.526367 611.056885,489.601562
	C616.142090,490.411652 621.289001,490.886932 626.424744,491.316193
	C636.689941,492.174072 646.955444,493.341461 657.240234,493.596710
	C692.239990,494.465302 725.133179,486.419678 756.061768,469.908356
	C775.938843,459.296906 796.419006,450.047852 818.880676,446.172150
	C827.752258,444.641388 836.701477,443.981873 845.695801,443.760773
	C849.043396,443.678497 850.521729,444.924316 850.412170,448.452606
	C850.215637,454.779663 850.104614,461.129608 850.428162,467.445862
	C850.661377,471.998413 849.183105,473.583801 844.518555,473.435089
	C829.102539,472.943420 813.975342,474.592407 799.470764,480.381805
	C789.307312,484.438416 780.527649,490.610626 771.886047,497.089233
	C772.845032,499.634247 774.953064,499.990967 776.679688,500.804932
	C798.557617,511.118591 821.693359,513.305054 845.435486,511.224030
	C848.805969,510.928619 850.494751,511.532318 850.371033,515.284729
	C850.157104,521.778809 850.250427,528.282654 850.083923,534.778992
	C850.027405,536.981506 849.801636,539.132507 846.458008,538.800232
	C832.396667,537.402466 818.249146,537.209595 804.194214,535.495972
	C793.692810,534.215576 783.583740,531.603638 773.761780,527.957703
	C748.791565,518.688660 722.850525,518.464722 696.856995,519.771973
	C681.465759,520.546082 666.292847,519.120300 651.040344,517.912109
	C637.126465,516.809998 623.347351,514.032471 609.325562,514.377441
	C603.879150,514.511414 598.524353,515.383850 593.810486,518.474060
	C594.524292,520.538635 596.112183,520.896851 597.407166,521.479309
	C638.542114,539.981812 680.966858,554.343384 725.595886,561.798584
	C746.145813,565.231384 766.839539,567.323669 787.615051,567.457336
	C806.897400,567.581482 826.213318,566.450867 845.300293,563.286987
	C849.155212,562.648010 850.499512,563.521301 850.393372,567.400574
	C850.224731,573.562378 850.282288,579.732971 850.373291,585.898010
	C850.414612,588.698608 849.560547,590.179504 846.457397,590.328735
	C824.509888,591.384705 802.586609,592.916870 780.606262,593.302917
	C734.374817,594.114807 690.116882,584.349548 646.849854,568.860840
	C608.265381,555.048462 570.828064,538.356628 532.562988,523.745117
	C500.317993,511.432343 467.549927,500.926971 433.369934,495.647461
	C417.628143,493.215912 401.760803,491.560944 385.758820,491.032135
	C372.603485,490.597412 359.439850,489.901154 346.310455,490.459106
	C328.214569,491.228088 310.115875,492.035645 292.070312,494.016571
	C275.778198,495.804993 259.513000,497.780975 243.214508,500.092804
	C242.726440,497.433258 244.846390,497.173126 246.047745,496.377197
	C265.226501,483.671295 286.564270,476.434143 308.880127,471.687103
	C331.021057,466.977234 353.409302,464.112885 375.982483,462.599304
	C376.599335,462.557983 377.203796,462.331512 377.727905,462.211151
	C378.133575,460.534210 376.977905,459.867218 376.225250,459.087982
	C347.173096,429.009308 319.036438,397.974915 286.453400,371.535767
	C262.117493,351.788605 236.104401,334.842529 205.835266,325.430939
	C198.531128,323.159851 191.124313,321.187225 183.505524,320.313568
	C179.177658,319.817322 177.794662,317.752899 178.892654,313.635193
	C180.262726,308.497040 181.540268,303.333405 182.793350,298.165161
	C183.566605,294.975922 184.786087,293.659515 188.692291,294.304901
	C209.972946,297.821014 230.224380,304.953033 250.720078,311.257324
	C253.673187,312.165680 256.586212,313.502197 260.106812,313.403412
	C260.407837,310.574463 258.946686,308.448578 258.058105,306.228241
	C248.098282,281.341156 238.171707,256.439911 228.007538,231.636215
	C226.411209,227.740707 227.029770,225.192200 229.951965,222.392609
	C235.452072,217.123306 240.944443,211.903107 248.116196,207.479675
	C278.230194,292.388062 318.840485,369.932495 389.579376,428.963318
	C391.271820,425.648865 389.734314,423.761292 389.107971,421.916351
	C381.441132,399.333435 376.349060,376.137726 372.875885,352.594604
	C369.171631,327.485077 368.627777,302.142242 369.959106,276.887024
	C371.688263,244.084427 377.603485,211.908981 387.668182,180.543549
	C389.045776,176.250595 391.123383,174.756241 395.502258,174.947067
	C402.488373,175.251541 409.498077,174.995941 416.497589,175.028000
	C418.287903,175.036209 420.179016,174.720901 422.533539,176.656754
	C412.704620,201.388107 405.588989,227.219986 402.357941,253.985214
	C399.139465,280.646149 399.057526,307.307617 402.633453,333.763733
	C405.487946,334.913544 406.942963,333.138550 408.542969,332.174103
	C454.271729,304.610168 489.673981,267.134308 515.531738,220.525543
	C522.737915,207.536377 528.886353,194.050919 533.744446,180.019089
	C535.063049,176.210510 537.087524,174.854172 541.041809,174.949051
	C550.485229,175.175659 559.937683,175.022186 570.088745,175.022186
	C568.912415,181.076630 566.826172,185.914261 564.863647,190.748764
	C537.313049,258.618896 493.052490,312.946075 432.446838,354.025787
	C426.659607,357.948456 420.956635,361.979187 416.632751,367.680817
	C411.396637,374.585388 410.712036,382.136871 413.623993,389.903381
	C418.833221,403.796997 425.579590,417.025604 432.153168,430.305328
	C438.432281,442.990295 445.113739,455.475952 451.602783,468.057251
	C452.689423,470.164062 453.817749,472.043549 456.404663,472.750214
	C469.398499,476.299683 482.359528,479.969238 495.341125,483.563965
	C495.945618,483.731354 496.636871,483.585419 498.559570,483.585419
	C483.250092,465.500916 477.896393,444.853394 477.858185,422.384186
	C480.880707,421.898834 482.322510,423.768646 483.962372,425.012939
	C502.359344,438.971954 514.274963,457.369751 519.007385,479.802612
	C520.958008,489.048798 524.974121,494.195831 534.062439,496.017792
	C537.438660,496.694641 540.647095,498.357971 543.832153,499.793152
	C546.538391,501.012573 548.076660,500.567352 549.123962,497.596558
	C554.307007,482.894440 555.646057,467.787842 554.248230,452.352966
	C551.319458,420.013489 532.627869,397.732086 507.727356,379.409515
	C500.074554,373.778381 492.333374,368.280670 484.034119,363.616211
	C482.914429,362.986908 481.546265,362.557831 481.046112,360.441284
	C485.602325,356.677826 491.102142,354.014832 496.434174,351.109619
	C497.729523,350.403839 499.031555,351.031799 500.249207,351.622375
	C530.962952,366.519958 557.353210,386.470337 575.440063,416.085388
	C579.628906,422.944122 582.534668,430.396088 585.136902,437.968323
	C585.512573,439.061646 585.725708,440.210846 587.174805,441.255554
	C592.062500,436.309814 596.224487,430.751709 600.338318,425.192596
	C630.350830,384.635773 650.006531,339.514740 659.333313,289.954193
	C663.808472,266.174408 665.562195,242.111023 664.536743,217.943100
	C664.002258,205.344818 662.960815,192.706177 660.493042,180.306671
	C659.548462,175.560791 661.357300,174.734802 665.273132,174.996399
	C667.264954,175.129456 669.270996,175.049286 671.737183,175.087234
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M700.808105,666.947021
	C710.894348,668.308655 720.588501,667.959473 730.216003,667.515747
	C760.365417,666.126160 790.495483,666.291809 820.637756,667.549500
	C828.792908,667.889832 836.964539,668.034119 845.125610,667.955994
	C849.349304,667.915649 850.653320,669.664551 850.390015,673.663818
	C850.040771,678.967957 850.486267,684.287048 849.559692,689.607239
	C849.026672,692.667542 847.654968,693.471619 844.908203,693.312561
	C836.767090,692.840881 828.615417,692.539307 820.478638,692.006592
	C795.496887,690.370972 770.490662,690.790344 745.580811,692.569214
	C717.789795,694.553772 690.727783,700.882263 664.089294,709.023621
	C632.639221,718.635620 602.644287,731.872009 572.554199,744.966003
	C523.799255,766.182251 472.957031,780.531311 420.503784,789.015076
	C398.843445,792.518433 376.986450,794.356262 355.037262,795.532471
	C334.368835,796.640076 313.723083,796.477478 293.084686,796.123901
	C269.146973,795.713806 245.183838,794.955750 221.434372,791.349121
	C220.081635,791.143677 218.738815,791.002747 217.762833,790.018005
	C211.539597,783.738464 206.377670,776.697937 201.364624,767.365662
	C305.363800,777.432373 406.643890,771.123291 505.662231,740.169250
	C505.348877,737.210266 503.131348,737.146240 501.642151,736.478821
	C485.577789,729.279358 468.549133,725.413391 451.331757,722.334290
	C426.575531,717.906921 401.606689,715.795349 376.458191,715.003235
	C327.648895,713.465637 279.417480,717.205994 231.998077,729.304932
	C218.918381,732.642151 206.181335,737.069031 193.764771,742.391174
	C192.415970,742.969360 191.151596,743.903870 189.102432,743.522278
	C185.628647,736.573364 183.530991,728.891479 181.375763,721.242188
	C180.425217,717.868530 183.488312,717.433472 185.417938,716.588928
	C196.256226,711.845337 207.430511,708.027588 218.796509,704.738953
	C241.205902,698.255066 264.101807,694.058716 287.164948,691.003784
	C301.478302,689.107788 315.907074,687.699402 330.411285,687.633606
	C336.565674,687.605713 342.717316,686.888123 348.871399,686.868835
	C405.287689,686.691467 460.618683,693.917175 514.387817,711.513184
	C521.820923,713.945679 529.080200,716.908508 536.509399,719.354065
	C549.342712,723.578552 561.754700,722.877563 573.416077,715.502991
	C574.375549,714.896240 575.241516,714.141418 576.144653,713.460144
	C575.515198,711.205994 573.583313,710.718262 572.077271,709.820801
	C538.371521,689.735046 501.746185,677.433105 463.574646,669.339050
	C432.088715,662.662659 400.231720,659.530762 368.044891,658.890564
	C315.249176,657.840454 263.451599,663.571106 213.108566,680.119019
	C202.342758,683.657776 191.816757,687.825684 181.633881,692.833435
	C176.554916,695.331177 175.776169,694.823364 175.696289,688.909180
	C175.610779,682.577148 175.839371,676.234497 175.548889,669.914124
	C175.382431,666.291748 176.840286,664.589600 180.076630,663.288635
	C203.776062,653.761536 228.194763,646.691772 253.233154,641.782410
	C294.990540,633.594849 337.143860,629.765930 379.709167,630.735291
	C441.999939,632.153931 501.755249,644.912598 558.700867,670.478699
	C571.327087,676.147278 583.722168,682.245605 595.850342,688.880981
	C610.693237,697.001526 625.168579,696.115234 639.488403,687.665527
	C641.049744,686.744141 642.742920,685.977112 643.938538,684.019958
	C639.365967,680.935120 634.218384,679.093689 629.254822,676.930115
	C594.903259,661.956360 561.033630,645.869751 526.239258,631.922852
	C496.770752,620.110718 466.200531,612.480652 434.731293,608.177979
	C412.607727,605.153137 390.390594,603.142578 368.117981,603.513367
	C323.323853,604.258972 278.731506,607.413025 235.219177,619.283875
	C217.043961,624.242310 199.273438,630.362915 181.954315,637.789978
	C180.172455,638.554138 178.466385,639.807983 175.666122,639.124084
	C175.666122,631.245789 175.631012,623.293457 175.684692,615.341736
	C175.704132,612.461548 178.266296,612.205566 180.181442,611.392517
	C204.314102,601.147156 229.377228,593.812378 254.841476,587.806213
	C279.779541,581.924072 304.997101,578.108032 330.630005,576.479614
	C354.778381,574.945496 378.895477,574.856323 402.922699,576.899963
	C444.494476,580.435974 485.011536,589.450684 524.486084,602.989624
	C556.652893,614.022156 587.754150,627.707153 618.963623,641.128357
	C634.563660,647.836914 650.243530,654.371399 666.227722,660.144958
	C677.270386,664.133606 688.660828,666.236572 700.808105,666.947021
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M268.135620,528.797180
	C285.668518,526.283752 302.798126,523.776306 320.049774,523.017883
	C342.473633,522.032043 364.854034,519.665527 387.397125,521.421875
	C398.984924,522.324707 410.641602,522.453308 422.245819,523.568970
	C449.285339,526.168396 475.778381,531.408081 501.979584,538.555298
	C535.312744,547.647949 565.966675,563.007690 596.977295,577.688110
	C629.594238,593.129089 663.574646,604.758484 699.161743,611.241394
	C714.531250,614.041321 730.071777,615.561157 745.656982,616.829346
	C760.481567,618.035767 775.291626,617.783997 790.103149,617.605286
	C809.075623,617.376404 827.943848,615.319519 846.814087,613.468201
	C847.623962,613.388733 848.448547,613.458130 850.201782,613.458130
	C850.201782,621.545105 850.271240,629.605774 850.155945,637.663879
	C850.124756,639.843689 848.195435,640.080261 846.480103,640.198364
	C831.211670,641.249939 815.923340,642.070190 800.678955,643.396606
	C786.199829,644.656433 771.714233,644.271606 757.228333,644.318115
	C698.552307,644.506775 643.138977,631.229980 591.010864,604.170776
	C557.231079,586.635925 522.277222,571.929382 485.456207,561.992737
	C458.581604,554.740234 431.248352,550.510254 403.404449,549.039062
	C379.771851,547.790344 356.164978,546.931824 332.540985,548.396179
	C279.927429,551.657471 229.270279,563.221863 180.994461,584.673035
	C179.657867,585.266968 178.411072,586.259766 176.343811,585.729980
	C174.952118,580.897095 175.769211,575.766907 175.721527,570.733521
	C175.593582,557.229004 175.703445,557.282410 188.351517,552.205627
	C214.059616,541.886841 240.507751,534.105103 268.135620,528.797180
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M569.239380,771.180908
	C606.488403,753.969910 643.762207,737.883484 683.152405,727.268311
	C721.320618,716.982422 760.136658,712.232727 799.649719,713.782043
	C812.936584,714.303040 826.143188,716.014465 839.293396,718.012512
	C840.874512,718.252686 842.631775,718.368225 844.265747,720.020935
	C842.295715,727.844788 839.708862,735.682129 836.092224,743.176514
	C834.988892,745.462830 832.936707,745.329590 830.911255,745.095825
	C806.547180,742.283997 782.072815,741.513672 757.611633,742.458374
	C728.360657,743.587952 699.983398,749.882080 672.122131,758.739563
	C642.216064,768.247131 613.456665,780.573608 585.250793,794.164795
	C538.132446,816.869080 488.688873,831.726379 437.189850,840.538757
	C404.211365,846.181946 371.117737,847.767212 337.785675,847.048096
	C306.370087,846.370300 277.289337,838.564392 251.720200,819.631348
	C251.185928,819.235718 250.617767,818.882874 250.106293,818.460571
	C249.862320,818.259033 249.714539,817.941040 249.465454,817.595947
	C250.748703,815.896606 252.532852,816.674561 254.000977,816.900635
	C269.777161,819.329712 285.628784,820.850891 301.565094,821.857300
	C321.537964,823.118774 341.508484,823.311462 361.482239,822.657593
	C384.758118,821.895691 407.828888,819.031982 430.790466,815.160461
	C478.895782,807.049438 524.243591,790.302795 569.239380,771.180908
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M614.640686,834.702881
	C605.565796,839.357239 596.811035,843.812012 588.094299,848.339844
	C585.995728,849.429932 583.928772,850.267700 581.485474,850.273499
	C560.493958,850.323120 539.502563,850.443359 518.511108,850.516113
	C518.069092,850.517639 517.625977,850.204346 517.032410,849.981079
	C517.541443,848.234253 519.181763,848.488281 520.238281,848.110718
	C549.318970,837.715820 576.693726,823.555359 604.518555,810.347107
	C640.381653,793.323242 677.373962,779.338257 716.397034,771.179565
	C746.713562,764.841125 777.289856,762.475098 808.154907,764.890564
	C813.311279,765.294189 818.798401,764.826843 824.316406,767.325562
	C819.460266,775.695679 814.322205,783.370178 807.792358,790.063110
	C806.189270,791.706116 804.066040,791.384827 802.115112,791.209900
	C788.475220,789.986511 774.860229,790.562073 761.205505,791.174927
	C735.272705,792.338928 710.088928,797.220581 685.418274,805.100159
	C661.083313,812.872559 637.794800,823.137756 614.640686,834.702881
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M657.058044,851.014160
	C653.359497,850.813660 650.048767,851.544373 646.810242,849.603027
	C688.527649,828.861633 731.582703,813.571777 779.169983,814.757568
	C779.208557,817.406372 777.645508,818.012878 776.479309,818.788635
	C752.084595,835.014038 726.161499,847.105469 696.380066,849.448547
	C683.438782,850.466736 670.515381,851.081543 657.058044,851.014160
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M178.261078,471.451355
	C185.548584,463.653198 194.763916,459.758942 204.098907,456.189026
	C223.444916,448.790710 243.294418,446.236420 263.772461,450.574005
	C265.406464,450.920105 267.020294,451.248596 268.376343,452.290924
	C268.599609,454.023895 267.194733,454.572632 266.178375,455.301453
	C245.594788,470.062317 222.795441,479.019958 197.478928,481.114441
	C192.176498,481.553131 186.813980,480.784058 181.735260,479.148865
	C178.337860,478.054993 175.540268,476.066956 178.261078,471.451355
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M676.894897,434.757599
	C697.446045,427.571350 718.052124,424.329834 739.392517,427.695038
	C743.184326,428.292969 746.842834,429.413269 750.292908,431.082947
	C752.511658,432.156677 755.103333,433.194183 755.122559,436.208923
	C755.140991,439.093567 752.819275,440.526001 750.667725,441.761993
	C745.309937,444.839783 739.562927,446.963623 733.580933,448.536621
	C712.521912,454.074188 691.571228,453.838043 670.731567,447.478668
	C667.284546,446.426788 663.808533,445.303040 660.810242,442.478882
	C665.521301,438.423157 671.266357,437.135559 676.894897,434.757599
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M848.136230,360.843933
	C825.448608,384.370850 798.147705,395.358246 766.000671,394.369568
	C765.244751,391.715057 766.652649,390.772766 767.638611,389.770477
	C786.433655,370.663513 807.866089,356.147552 834.814514,351.215332
	C837.634277,350.699280 840.439209,350.592041 843.252136,350.796539
	C850.471313,351.321350 851.868286,354.053925 848.136230,360.843933
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M767.230591,251.195740
	C754.610474,265.373901 740.121033,276.055725 722.322693,281.276337
	C720.543396,279.900024 721.249023,278.739349 721.636169,277.725006
	C730.364075,254.854141 743.594482,235.223785 763.332153,220.380646
	C767.996948,216.872620 773.096008,213.945450 778.885986,212.700607
	C784.400391,211.515030 786.513062,213.529999 785.398621,219.072662
	C783.202332,229.995804 776.735657,238.734634 770.320496,247.459076
	C769.434631,248.663757 768.416748,249.771393 767.230591,251.195740
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M260.900818,420.346436
	C257.458832,423.413116 253.521179,421.514160 250.142212,421.061188
	C229.318069,418.269592 209.632141,411.933197 191.953568,400.219055
	C186.922775,396.885590 182.568298,392.767639 178.881744,387.985626
	C177.214355,385.822723 175.830475,383.307831 177.009705,380.595093
	C178.270248,377.695374 181.234848,377.610870 183.914993,377.726257
	C195.506836,378.225342 206.566086,381.142792 217.192078,385.641174
	C232.575684,392.153656 246.244003,401.140503 256.915253,414.224213
	C258.377319,416.016815 259.549927,418.045563 260.900818,420.346436
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M592.323242,368.656097
	C578.218262,351.050629 568.953491,331.535309 565.059570,309.646454
	C563.781799,302.463837 563.549133,295.109039 565.469360,287.904114
	C566.152039,285.342773 566.676025,282.415314 569.884521,281.872498
	C573.067200,281.334015 575.209534,283.478210 577.046875,285.662018
	C585.002502,295.118256 590.173889,306.031891 593.584412,317.832397
	C598.071899,333.358643 600.146667,349.048431 596.173767,365.009003
	C595.786438,366.565002 596.930176,370.595551 592.323242,368.656097
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M609.664978,184.711670
	C612.415955,181.784988 614.795410,178.927582 617.955200,177.030518
	C622.533569,174.281830 625.604675,175.587585 626.526062,180.859467
	C628.199402,190.434052 625.989258,199.703522 623.348206,208.779129
	C617.950256,227.328339 608.954895,243.783325 594.119568,256.610779
	C592.865051,257.695496 591.540161,258.707428 590.182800,259.660248
	C589.677856,260.014709 588.985596,260.102509 587.143677,260.738129
	C587.508606,232.887222 592.875366,207.364304 609.664978,184.711670
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M444.617126,258.480042
	C443.420258,259.324249 442.646332,260.305481 440.911102,260.006470
	C439.070831,254.725723 440.408844,249.176926 440.939423,243.943359
	C442.833405,225.263016 447.782898,207.415405 457.735901,191.222549
	C460.820953,186.203400 464.647034,181.841827 469.148468,178.104797
	C474.560333,173.611893 479.011475,175.089844 479.723145,182.028473
	C480.878479,193.292694 478.177429,204.140427 474.427551,214.655685
	C468.348480,231.702530 459.224548,246.777588 444.617126,258.480042
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M328.166931,223.915009
	C327.090912,240.707397 324.529480,256.585358 314.666809,271.264252
	C310.850616,265.449341 309.253754,259.716583 307.629425,254.036163
	C302.455750,235.943481 300.263855,217.611404 303.696625,198.900452
	C304.632446,193.799484 306.272095,188.911606 308.886810,184.415466
	C310.208679,182.142517 311.455200,179.572113 314.737671,179.685822
	C317.943787,179.796875 319.295288,182.252075 320.419556,184.647156
	C324.194427,192.688721 326.345856,201.211121 327.274048,210.021866
	C327.743408,214.477325 327.881836,218.967667 328.166931,223.915009
z"/>
</svg>
</template>

<script>
export default {
  name: 'MainLogo',
  props: {
    fillColor: {
      type: String,
    },
  }
}
</script>
