<template>
    <v-img contain height="10vh"
           style="margin-bottom: 120px"
           class="mx-auto"
           src="~@/assets/custom/images/login-logo.png"/>
</template>

<script>
export default {
  name: 'LogoNoText'
}
</script>
